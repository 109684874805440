import React, { ReactElement } from "react";

import classNames from "classnames";

import { FooterData } from "data/footer";

import { FooterLinks } from "./FooterLinks";
import { FooterLogo } from "./FooterLogo";
import * as styles from "./MarketingFooterContent.css";

interface Props {
  data: FooterData;
  isConcise?: boolean;
}
const MarketingFooterContent = ({ data, isConcise = false }: Props): ReactElement => (
  <div className={styles.container}>
    <div className={styles.column}>
      <FooterLogo />
    </div>
    <div className={styles.divider} />
    {!isConcise && (
      <>
        <div className={styles.divider} />
        <div className={classNames(styles.links, styles.column)}>
          <FooterLinks data={data} />
        </div>
      </>
    )}
  </div>
);

export default MarketingFooterContent;
